const actionTypes = {
  SWITCH_TEAMS: 'SWITCH_TEAMS',
  SET_PREFERENCE: 'SET_PREFERENCE',
  DISTRIBUTE: 'DISTRIBUTE',
  FINAL_DECLARE: 'FINAL_DECLARE',
  PLAY_CARD: 'PLAY_CARD',
  CARD_BACK: 'CARD_BACK',
  COLLECT: 'COLLECT',
  UNDO: 'UNDO',
  DECLARE: 'DECLARE',
  LAUNCH_GAME: 'LAUNCH_GAME',
  GET_SCORE: 'GET_SCORE',
  RESET: 'RESET',
};

export default actionTypes;